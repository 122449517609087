@media screen and (max-width:451px) {
    .pagination {
        margin-top: -30px !important;
    }
}

@media screen and (min-width:451px) {
    .pagination {
        margin-top: 0px !important;
    }
}

.flex {
    display: flex;
}

.separator {
    margin-top: 40px !important;
    text-align: center !important;
}

.separator span {
    position: relative !important;
    top: -26px !important;
    font-weight: 900;
    width: 200px !important;
    background: white !important;
}

.custom-table-th {
    font-size: 12px !important;
}

.custom-table-th:hover {
    cursor: pointer;
}

.badge-orange {
    color: #fff;
    background-color: orange;
}

.badge-orange[href]:hover, .badge-orange[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: darkorange;
}

.badge-purple {
    color: #fff;
    background-color: #6f42c1;
}

.badge-red {
    color: #fff;
    background-color: #c14242;
}