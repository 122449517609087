/* @media screen and (max-width:451px) {
    .export {
        justify-content: flex-start !important;
    }
} */

@media screen and (max-width:451px) {
    .pagination {
        margin-top: -30px !important;
    }
    .exportbtn {
        align-self: flex-start !important;
    }
}

@media screen and (min-width:451px) {
    .pagination {
        margin-top: 0px !important;
    }
}

@media screen and (min-width:451px) {
    .exportbtn {
        align-self: flex-end !important;
    }
}

.separator {
    margin-top: 40px !important;
    text-align: center !important;
}

.separator span {
    position: relative !important;
    top: -26px !important;
    font-weight: 900;
    width: 200px !important;
    background: white !important;
}

.custom-table-th {
    font-size: 12px !important;
}


/* .left{
    
} */