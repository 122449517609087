@media screen and (max-width:451px) {
    .pagination {
        margin-top: -30px !important;
    }
}

@media screen and (min-width:451px) {
    .pagination {
        margin-top: 0px !important;
    }
}

.separator {
    margin-top: 40px !important;
    text-align: center !important;
}

.separator span {
    position: relative !important;
    top: -26px !important;
    font-weight: 900;
    width: 200px !important;
    background: white !important;
}

.custom-table-th {
    font-size: 12px !important;
}

@media screen and (max-device-width: 717px) {
    .btn-left {
        display: flex;
        justify-content: start;
        width: 97%;
    }
}

@media screen and (max-device-width: 460px) {
    .invoiceHeader {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
    }
    .invoiceHeader button {
        margin-left: 20px !important;
    }
}