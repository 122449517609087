@import url(https://fonts.googleapis.com/css?family=Roboto:100);

/*#395CBE background color => Transaparency change only*/


/*https://codepen.io/jidelambo/pen/RaRygY*/

body {
    padding: 0px !important;
    min-height: auto !important;
}

html {
    position: relative;
    min-height: 100%;
}

.bg-full-image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
}

JSResult Skip Results Iframe EDIT ON body {
    margin-top: 100px;
    background-color: #137b85;
    color: #fff;
    text-align: center;
}

h1 {
    font: 2em 'Roboto', sans-serif;
    margin-bottom: 40px;
}

.icon-info {
    font-size: 14px !important;
    position: relative;
    top: -3px;
    left: -1px;
}

.kycIcon {
    width: 20px !important;
    height: 20px !important;
    margin-left: 6px;
}

#loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid #047bf8;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

.filterStartDate::before {
    content: "Start Date";
    width: 100% !important;
    color: gray !important;
}

.editor-wrapper {
    border: 2px solid #dde2ec;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.rdw-embedded-modal,
.rdw-link-modal {
    height: fit-content !important;
}

.content-wrapper {
    border-top: 2px solid #dde2ec;
    min-height: 200px;
}

.filterEndDate::before {
    content: "End Date";
    width: 100% !important;
    color: gray !important;
}

.custom-tooltip-coin2 {
    display: initial !important;
    position: relative !important;
}

.custom-tooltip-coin2:hover>.earningTooltip1 {
    display: inline !important;
}

.custom-tooltip-coin2:hover>.earningTooltip2 {
    visibility: visible;
}

.custom-tooltip-coin2.tooltip-right:hover:after {
    right: 20% !important;
    left: inherit !important;
}

.earningTooltip1 {
    position: absolute !important;
    width: 314px !important;
    font-size: 14px !important;
    /* box-shadow: 1px 1px 5px rgb(104, 104, 255); */
    box-shadow: 1px 1px 2px rgb(116, 116, 116);
    padding: 10px;
    margin-top: -5px !important;
    white-space: break-spaces;
    background-color: #EFEFEF;
    z-index: 98;
    /* visibility: hidden; */
    display: none!important;
    border-radius: 10px;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.bg-full-image:before {
    background-image: url(../img/header_bg.jpg?v3) !important;
    background-size: cover !important;
    background-attachment: fixed;
    content: "";
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 200vh !important;
}

.logowithText {
    display: flex !important;
    align-items: center;
    width: 253px !important;
    margin-left: -10px;
}

.footer {
    position: absolute;
    bottom: 0;
    justify-content: center;
    width: 100%;
    /* margin-top: 5em; */
    /* line-height: 80px; */
}

.table-responsive>.row {
    flex-wrap: nowrap;
}

.footer-container .footer {
    height: inherit !important;
    line-height: inherit !important;
    margin-bottom: 10px !important;
}

.base-footer .footer {
    position: relative !important;
    margin-top: 3 !important;
}

.custom-wrapper .footer {
    position: relative;
}

.color-white {
    color: white !important;
}

.bg-overlay {
    background: rgba(63, 81, 181, 0.5) !important;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.all-wrapper.with-pattern {
    background-image: none !important;
    min-height: 65vh !important;
}

.main-content-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.custom-content-container {
    margin-top: 30px;
}

.auth-box-w.wider {
    max-width: 100% !important;
    border-radius: 50px !important;
}

.auth-box-w.terms-wider {
    max-width: 90% !important;
}

.auth-box-w.login-box {
    border-radius: 50px !important;
}

.auth-box-w .logo-w {
    padding: 20px 0 0 0 !important;
}

.auth-box-w {
    background-color: #395cbe !important;
    opacity: 0.95 !important;
}

.custom-form .form-control {
    padding: 18px !important;
    border-radius: 30px !important;
    border: none !important;
    color: grey !important;
    font-size: 16px !important;
}

.custom-form ::placeholder {
    color: grey !important;
    opacity: 1;
}

.custom-submit-button {
    width: 100% !important;
    border-radius: 50px !important;
    padding: 20px !important;
    background: linear-gradient(134deg, #56b8e4 0%, #6b8ccf 100%) !important;
    border: 1px solid white !important;
}

.table-striped thead tr th,
.table-lightfont thead tr th {
    white-space: nowrap !important;
}

.table-striped thead tr th,
.table-lightfont thead tr th {
    white-space: nowrap !important;
}

.table-striped tbody tr td,
.table-lightfont tbody tr td {
    white-space: nowrap !important;
}

.auth-box-w .buttons-w {
    border-top: none !important;
}

.info-bottom-link {
    color: white !important;
    float: right !important;
    padding-right: 15px !important;
    font-size: 18px !important;
}

.logout-link {
    color: white !important;
    float: right !important;
    padding: 5px 20px !important;
    font-size: 18px !important;
    border: 2px solid white;
    border-radius: 50px;
}

.terms-block {
    font-size: 18px !important;
    color: white !important;
}

.auth-box-w form {
    padding: 20px 40px 40px 40px !important;
}

.type-selector {
    border: 1px solid white !important;
    border-radius: 50px !important;
    padding: 15px !important;
    color: white !important;
}

.hour-btn {
    border-radius: 8px 0px 0px 8px;
}

.day-btn {
    border-radius: 0px 8px 8px 0px;
}

.mgtp-sm-4 {
    margin-top: 1.5rem;
}

.onboarding-title {
    /* font-size: 20px; */
    margin-right: 20px !important;
}

.table th {
    font-size: 12px !important;
}

.tab button {
    background-color: inherit;
    border: 0.5px solid #ebebeb;
    outline: none;
    cursor: pointer;
    padding: 8px 12px 8px 12px;
    transition: 0.3s;
    font-size: 14px;
    color: #395cbe;
}

.tab-group .download-btn {
    background-color: #395cbe;
    border-radius: 8px;
    color: #ffffff;
    padding: 0px 8px;
    cursor: pointer;
    font-size: 14px;
    border: 0.5px solid #395cbe;
    outline: none;
    margin-left: 10px;
}

.copyright-text {
    /* margin-top: 3em !important; */
    position: relative;
    color: white !important;
    font-size: 18px !important;
}

.type-selector label {
    padding-right: 15px !important;
    padding-left: 0.5rem !important;
    font-size: 16px;
}

.type-selector span {
    padding-left: 5px !important;
}

.gender-container label {
    padding-right: 10px !important;
    font-size: 16px;
}

.gender-container span {
    padding-left: 5px !important;
}


/* Styles for wrapping the search box */

.main {
    width: 50%;
    margin: 50px auto;
}

.custom-form .input-group-text {
    position: absolute !important;
    z-index: 999 !important;
    top: 15% !important;
    left: 0% !important;
    padding: 15px !important;
    border-radius: 50px !important;
    margin-left: 5px !important;
    background: #3a5ebe !important;
    color: white !important;
}

.custom-form .input-group input {
    text-indent: 2.75rem !important;
}

span.error {
    color: #ccd9e8 !important;
    font-size: 15px !important;
}

.min-width-40 {
    min-width: 40% !important;
}

.login-container .auth-box-w .buttons-w {
    padding-top: 2rem !important;
}

.custom-form .form-control {
    width: 100% !important;
}

.disabled {
    opacity: 0.9 !important;
    cursor: not-allowed !important;
}

.custom-top-bar {
    background-color: #395cbe !important;
}

.top-bar .logged-user-w {
    margin: 0px 24px!important;
}

.custom-bg-overlay {
    z-index: 0 !important;
}

.top-bar:not(.admin-top-bar) .top-menu-controls {
    padding: 25px 0 !important;
}

.customLogo {
    margin-left: 15px !important;
}

.custom-username {
    color: white !important;
    font-size: 16px;
}

.custom-wrapper {
    height: 100% !important;
    min-height: 100vh !important;
}

.custom-background-color {
    background-color: #5e9ed8 !important;
    height: auto !important;
    min-height: 100vh !important;
}

.user-dashboard-header-bg-color {
    background-color: #5e9ed8 !important;
    height: auto !important;
    min-height: auto !important;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.5) !important;
}

.custom-element-box {
    background-color: #395cbe !important;
    border-radius: 0 !important;
    color: white !important;
}

.horizontal-scroll {
    overflow: auto!important;
}

.spaceBetween {
    justify-content: space-between !important;
}

.custom-element-box .element-box {
    background-color: #395cbe !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
}

.kyc-container .element-wrapper {
    padding-bottom: 0.2rem !important;
}

.custom-element-box .form-header {
    color: white !important;
}

.kyc-container .form-control:not(.no-border-radius) {
    background-color: transparent !important;
    border-radius: 50px !important;
    color: white !important;
}

.kyc-container ::placeholder {
    color: white;
    opacity: 0.5;
}

.permian-button {
    padding: 15px 60px;
    border-radius: 50px;
    background-color: #395cbe !important;
}

.permian-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}


/*.kyc-contact-block {
  min-height: 410px !important;
}
*/

.kyc-container select {
    color: white !important;
}

.kyc-container .dropzone {
    background-color: #395cbe !important;
    border: 2px dashed white !important;
}

.resource-continaer {
    background-color: #395cbe !important;
}

.centerofthescreen {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.min-height-90vh {
    min-height: 90vh !important;
}

.resource-continaer.dropzoneIdContainer {
    padding: 20px !important;
}

.country-select2 .cntry__control {
    background-color: transparent !important;
    border-radius: 50px !important;
    color: white;
}

.country-select2 ::placeholder,
.cntry__single-value,
.cntry__placeholder,
.cntry__indicators svg {
    color: white !important;
}

.cntry__menu {
    background-color: #5e9ed8 !important;
    color: white !important;
}

.cntry__option--is-focused {
    background-color: #395cbe !important;
}

.custom-content-container {
    overflow: hidden !important;
}

.dark-blue-theme-color {
    border-radius: 8px;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px !important;
}

.card-inner-padding {
    padding: 24px;
}

.cpad {
    padding: 24px !important;
}

.text-xl {
    font-size: 20px;
}

.text-default-color {
    color: #333333;
}

.dob-container .react-datepicker-wrapper,
.dob-container .react-datepicker__input-container {
    display: inline !important;
}

.text-xl {
    font-size: 20px;
}

.text-default-color {
    color: #333333;
}

.earnings-select {
    height: 28px !important;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #395cbe !important;
    font-size: 12px !important;
    margin-left: 12px;
    color: #395cbe;
}

.kyc-agree-container {
    margin-top: 25px !important;
    padding-left: 0px !important;
}

.kyc-agree-container label {
    font-size: 16px !important;
}

.progress-steps-container .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: white;
}

.dropzoneIdContainer {
    padding: 10px 0 !important;
}

.active-currency {
    background: #EBEBEB !important;
}

.tab-group .download-btn {
    background-color: #395cbe;
    border-radius: 8px;
    color: #ffffff;
    padding: 0px 8px;
    cursor: pointer;
    font-size: 14px;
    border: 0.5px solid #395cbe;
    outline: none;
    margin-left: 10px;
}

.earnings-select {
    height: 28px !important;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #395cbe !important;
    font-size: 12px !important;
    margin-left: 12px;
    color: #395cbe;
}


/*.documentType{
background: #5E9ED8 !important;
}
.documentType option{
color:#395CBE !important;
}*/

.rdtPicker {
    background-color: #5e9ed8 !important;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #5e9ed8 !important;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #5e9ed8 !important;
}

.rdtPicker thead tr:first-child th:hover {
    background: #5e9ed8 !important;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background: #5e9ed8 !important;
}

.cntry__input {
    color: white !important;
}

.kyc-status-container {
    color: white !important;
}

.progress-icon-container {
    padding: 12px !important;
    background-color: #395cbe !important;
    border-radius: 50%;
}

.kycstatus-head {
    font-weight: 200 !important;
    font-size: 55px !important;
}

body.menu-position-side .content-w {
    border-radius: 0 !important;
}

.required-sign {
    color: red !important;
}

.admin-container,
.admin-container .menu-w,
.user-dashboard-container,
.user-dashboard-container .menu-w {
    height: 100% !important;
    min-height: 100vh !important;
}

.progress-icon-container.bg-red {
    background-color: red !important;
}

.kyc-status-button {
    background-color: #395cbe !important;
    border-radius: 50px !important;
    padding: 15px 20px !important;
}

.text-bold {
    font-weight: bolder !important;
}

.buy-xpr-container {
    font-size: 16px !important;
}

.dark-blue-theme-color {
    background-color: #395cbe !important;
    border-radius: 8px;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
}

.dark-blue-font-color {
    color: #395cbe !important;
}

.buy-xpr-header-block {
    font-size: 18px !important;
}

.active-currency {
    background: #EBEBEB !important;
}

.buy-xpr-input {
    width: 21% !important;
    /* padding-left: 20px !important; */
    height: 30px !important;
    border-radius: 50px 0px 0px 50px;
    opacity: 1 !important;
    border: white !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    padding-right: 10px;
}

.buy-xpr-currencey-select {
    /* width: 8% !important; */
    height: 30px !important;
    border-radius: 0px 50px 50px 0px;
    background-color: transparent;
    border-color: white !important;
    padding: 0 2px !important;
}

.buy-xpr-currencey-select option {
    background-color: #59adde !important;
}

.buy-xpr-currencey-select,
.buy-xpr-currencey-select option {
    color: white !important;
}

.card-inner-padding {
    padding: 24px;
}

.tokens-xpr-input {
    width: 30% !important;
    padding-left: 3px !important;
    height: 30px !important;
    border-radius: 50px 0px 0px 50px;
    opacity: 1 !important;
    border: white !important;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    /* padding-right: 10px; */
}

.token-symbol-input {
    padding-left: 3px !important;
    padding-right: 3px !important;
    width: 20% !important;
    background-color: #59adde !important;
    border: white !important;
    opacity: 1 !important;
    border-radius: 0px 50px 50px 0px;
    height: 30px !important;
    color: white !important;
}

.highligher {
    font-size: 16px;
    font-weight: 600;
}

.receive-text {
    font-size: 16px;
}

.min-width-50 {
    min-width: 45% !important;
}

.eth-contribution-input {
    width: 55% !important;
    border-radius: 50px !important;
    height: 30px !important;
    padding-left: 10px !important;
    border: white !important;
    opacity: 1;
    background-image: linear-gradient( 134deg, #59adde 0%, #57ccec 100%) !important;
    padding-left: 10px !important;
}

.buy-xpr-container h3,
.buy-xpr-container h6 {
    font-weight: 300 !important;
}

.buy-xpr-buttons {
    border-radius: 50px !important;
    margin-left: 20px !important;
    background-color: transparent;
    padding: 5px 15px !important;
    font-size: 16px;
    border: 1px solid white !important;
    font-weight: 200 !important;
}

.qrcode-image-container img {
    margin-top: 15% !important;
}

.buy-online-payment-buttons {
    width: 15%;
    background-color: transparent;
    border-radius: 50px !important;
    border: 1px solid white !important;
}

.admin-error-msg,
.validation-red-error {
    color: red !important;
}

.instructon-modal-container {
    padding: 0 !important;
}

.instructon-modal-container .onboarding-modal .onboarding-content .onboarding-text {
    color: black !important;
}

.required-field {
    color: red;
}

.my-account-container .my-account-header-block h5 {
    font-weight: 400 !important;
}

.account-button {
    padding: 5px 10px !important;
    border-radius: 50px;
    background-color: #395cbe !important;
    border: 1px solid white !important;
}

.account-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5) !important;
}

.my-account-button {
    margin: 10px 0 !important;
    padding: 10px 40px !important;
}

.my-account-container .kyc-success-icon-container {
    padding: 12px !important;
    background-color: white !important;
    color: #395cbe !important;
    border-radius: 50%;
}

.my-account-container .verified-text {
    font-size: 1.3rem !important;
}

.my-account-container h5 span {
    font-size: 1.2rem !important;
}

.buy-currency-box-display {
    padding: 2.5rem !important;
    border-radius: 50px !important;
    background-color: grey !important;
}

.buy-copy-button {
    padding: 15px;
    border-radius: 50px;
    background-color: #395cbe !important;
    border-color: white !important;
    margin-left: 15px !important;
}

.modal-contribution-address {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.my-account-header-block {
    overflow: auto;
    min-height: 240px !important;
}

.two-fa-code-input {
    width: 250px !important;
    display: inline-block !important;
    margin: 10px 15px !important;
}

.two-factor-heading-text {
    font-size: 16px !important;
}

.receiving-eth-address {
    border-radius: 30px !important;
    padding: 8px !important;
    border: none !important;
    color: grey !important;
    font-size: 14px !important;
}

.kyc-file-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: 8rem !important;
    border: 1px white dashed;
}

.kyc-multifile-upload-container {
    width: 100% !important;
    display: table !important;
    cursor: pointer !important;
    height: auto !important;
    border: 1px white dashed;
    min-height: 8rem !important;
}

.upload-text-display {
    display: table-cell !important;
    vertical-align: middle !important;
}

.width-auto {
    width: auto !important;
    display: inline !important;
}

.display-inline {
    display: inline !important;
}

.login-container {
    margin-top: 40px;
}

.onboarding-modal .onboarding-slide .onboarding-content {
    padding: 40px !important;
}

.progressbar {
    counter-reset: step !important;
}

.progressbar li {
    list-style: none !important;
    display: inline-block !important;
    width: 25% !important;
    position: relative !important;
    text-align: center !important;
    /* cursor: pointer !important; */
}

.progressbar li:before {
    content: counter(step) !important;
    counter-increment: step !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    border: 1px solid #ddd !important;
    border-radius: 100% !important;
    display: block !important;
    text-align: center !important;
    margin: 0 auto 10px auto !important;
    background-color: #fff !important;
}

.progressbar li:after {
    content: "" !important;
    position: absolute !important;
    width: 100% !important;
    height: 1px !important;
    background-color: white !important;
    top: 15px !important;
    left: -50% !important;
    z-index: 0 !important;
}

.progressbar li:first-child:after {
    content: none !important;
}

.progressbar li.active {
    color: white !important;
}

.progressbar li.active:before {
    border-color: white !important;
    color: #395cbe !important;
    font-weight: bolder;
}

.progressbar li.active+li:after {
    background-color: white !important;
    z-index: 1 !important;
}

.has-sub-menu {
    cursor: pointer;
}

.buttton-center {
    display: flex;
    width: 100%;
    justify-content: center;
}

.filter-element-wrapper {
    padding-bottom: 0rem !important;
}

.filter-element-wrapper .form-control {
    width: 100% !important;
}

.kyc-filter-element-wrapper .form-control,
.users-filter-element-wrapper .form-control,
.filter-element-withdrawal-request-wrapper .form-control {
    width: 18rem;
}

.button-center {
    display: flex;
    width: 100%;
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer !important;
}

.dashboard-header {
    font-size: 15px !important;
}

.content-w {
    overflow: auto;
}

.color-red {
    color: red !important;
}

.styles_overlayCenter__YHoO7 {
    justify-content: center !important;
}

.react-confirm-alert-body>h1 {
    margin-top: 0;
}

.dashboard-statistics-container:not(:last-child),
.user-statistics-container {
    border-right: 1px solid white !important;
}

.barrel-display {
    display: inline !important;
    padding: 3px 10px !important;
    border-radius: 50px !important;
    background: white !important;
    color: #395cbe !important;
}

.react-confirm-alert-button-group {
    display: flex;
    justify-content: center !important;
}

.bg-white {
    background: white !important;
}

.dashboard-my-investment-list td {
    padding: 0.25rem !important;
}

.el-tablo .value {
    font-size: 1rem !important;
}

.dashboard-my-investment-list.table thead th {
    border-bottom: none !important;
}

.text-underline {
    text-decoration: underline !important;
}

.custom-tooltip {
    display: inline;
    position: relative;
}

.custom-tooltip1 {
    display: inline;
    position: relative;
}

.custom-tooltip:hover:after {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    content: attr(tooltip-title);
    left: 20%;
    padding: 0.25rem 0.5rem;
    position: absolute;
    z-index: 98;
}

.custom-tooltip1:hover:after {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    font-size: 14px !important;
    content: attr(tooltip-title);
    left: 20%;
    white-space: pre;
    /* width: 200px !important; */
    padding: 0.25rem 0.5rem;
    position: absolute;
    z-index: 98;
}

.custom-tooltip:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 99;
}

.custom-tooltip1:hover:before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    content: "";
    left: 50%;
    position: absolute;
    z-index: 99;
}

.custom-tooltip.tooltip-right:hover:after {
    right: 20% !important;
    left: inherit !important;
}

.custom-tooltip1.tooltip-right:hover:after {
    right: 20% !important;
    left: inherit !important;
}

.custom-tooltip.tooltip-right:hover:before {
    right: 50% !important;
    left: inherit !important;
}

.custom-tooltip1.tooltip-right:hover:before {
    right: 50% !important;
    left: inherit !important;
}

.display-inherit {
    display: inherit !important;
}

.white-background {
    background-color: white !important;
}

.xpr-chart-header {
    color: #395cbe !important;
    font-size: 16px !important;
}

.qrcode-image-container img {
    padding-bottom: 20px !important;
}

.document-type-header {
    color: #395cbe !important;
}

.filepond--root {
    min-height: 8rem !important;
    /*max-height: 15rem !important;*/
    border: 2px dotted white !important;
}

.filepond--panel-root {
    background-color: transparent !important;
}

.filepond--drop-label {
    color: white !important;
    top: 20% !important;
}

.filepond--file-action-button.filepond--action-process-item {
    display: none !important;
}

.split-radio-container label {
    min-width: 35% !important;
}

.understanding2-split-radio-container label {
    min-width: 33% !important;
}

.wealth-split-radio-container label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.understanding-split-radio-container label {
    min-width: 20% !important;
    margin-top: 5px !important;
}

.radio-half-width-container label {
    min-width: 50% !important;
    margin-top: 5px !important;
}

.financial-products-invested label {
    min-width: 25% !important;
    margin-top: 5px !important;
}

.financial-information-textarea {
    background-color: transparent !important;
    color: white !important;
    resize: none !important;
}

.signature-pad-container {
    border: 2px dotted white !important;
}

.clear-sign-action {
    font-size: 0.8rem !important;
}

.terms-container {
    padding: 20px 40px 60px 40px !important;
    color: white !important;
}

.cntry__menu-list {
    max-height: 10rem !important;
}

.identification-data-container,
.identification-data-container h3 {
    font-size: 16px !important;
}

.identification-data-container .row {
    margin-bottom: 10px !important;
}

.signature-container-img {
    max-width: 100% !important;
    width: 100% !important;
}

.user-dashboard-content {
    background-color: #59adde !important;
}

.user-dashboard-menu {
    background-color: white !important;
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important;
    z-index: 99 !important;
    top: 91px !important;
}

.user-dashboard-menu li span {
    color: #395cbe !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    opacity: 1 !important;
}

.header-fixed-menu {
    position: fixed !important;
    width: 100% !important;
    top: 0 !important;
    z-index: 100 !important;
}

.center-content {
    padding-top: 91px !important;
}

.user-dashboard-content {
    margin-left: 205px !important;
    position: relative !important;
}

.alert-dark-blue {
    background-color: #395cbe !important;
    border-color: #395cbe !important;
    color: white !important;
    font-size: 18px !important;
}

.worth-highlighter {
    font-size: 13px !important;
    color: #d8cdcd !important;
    /* font-style: italic !important; */
}

.user-dashboard-content {
    min-height: 100vh !important;
}

.o-auto {
    overflow: auto;
}

.content-end {
    display: flex;
    justify-content: flex-end;
}

.content-box {
    padding-bottom: 0 !important;
    width: 100%;
}

.progressbar li:after {
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    margin-right: 15px;
}

.buyXprNotes {
    padding: 40px !important;
}

.buyXprNotes ol {
    padding-inline-start: 15px;
}

.instructon-modal-container OL {
    counter-reset: item;
}

.instructon-modal-container LI {
    display: block;
}

.instructon-modal-container LI:before {
    content: counters(item, ".") " ";
    counter-increment: item;
}

.clearNumber {
    counter-reset: list !important;
}

.clearNumber .incrementNo li {
    list-style: none !important;
    position: relative !important;
}

.clearNumber li::before {
    counter-increment: list !important;
    content: "(" counter(list, lower-alpha) ") " !important;
}

.clearAlpha {
    counter-reset: list !important;
}

.clearAlpha li {
    list-style: none !important;
    position: relative !important;
}

.manageuser-btn-group {
    justify-content: left;
    width: 97%;
    margin-top: 10px !important;
}

.clearAlpha li::before {
    counter-increment: list !important;
    content: "(" counter(list, lower-roman) ") " !important;
}

.clearNumbers {
    counter-reset: number !important;
}

.clearNumbers li::before {
    counter-increment: number !important;
    content: " " counter(number) ". " !important;
}

.underline {
    text-decoration: underline !important;
}

.instructionCheck {
    margin-left: 1rem !important;
    display: inline !important;
    /* margin-bottom: 1rem !important; */
}

.declarationCheck {
    float: left !important;
}

.declarationLabel {
    display: block !important;
    margin-left: 40px !important;
}


/* .permian-alert {
  color: #ff0101 !important;
  background-color: #f9dfda !important;
  border-color: #f9dfda !important;
  font-size: 16px !important;
}

.permian-alert a {
  color: white !important;
  text-decoration: underline !important;
} */

.my-account-error-msg {
    font-size: 16px !important;
    /* box-shadow: 2px 3px red; */
    border: 1px solid red;
}

.my-account-error-msg a {
    color: white !important;
    text-decoration: underline !important;
}

.myaccount-error-icon {
    color: red !important;
    font-size: 20px !important;
}

.assumption-block {
    color: black !important;
}

.width-350 {
    width: 350px !important;
}

.token-statistics-element-wrapper {
    padding-bottom: 1.5rem !important;
}

.verifier-modal {
    min-width: 450px !important;
}

.event-success-message {
    padding: 15% 10%;
}

body.page-loaded .css3-spinner {
    display: none !important;
}

.menu-w.menu-layout-compact ul.main-menu {
    padding: 1rem !important;
}

.left-menu-sidebar {
    min-width: 18% !important;
}

.form-buttons-w.custom-submit-button2 {
    margin-top: 0 !important;
    border-top: none !important;
}

.submit-button-padding-set {
    padding: 6px 30px !important;
}

.wealth-split-radio-container label {
    min-width: 25% !important;
    margin-left: 3px !important;
    margin-top: 5px !important;
}

@media (max-width: 767px) {
    .kyc-document-img {
        height: 150px;
    }
    .mobile-center-img {
        display: flex;
        justify-content: center;
    }
    .m-btn-center {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 0 !important;
    }
}

.auth-box-w.signupwidth {
    margin-top: 8em;
    min-width: 50% !important;
}

@media screen and (min-width: 120px) and (max-width: 450px) {
    .react-bootstrap-table-pagination-list {
        margin-top: 30px !important;
    }
    .pull-right {
        margin-top: 10px !important;
    }
    .content-wrapper {
        min-height: 100px;
    }
}

@media screen and (min-width: 120px) and (max-width: 480px) {
    .base-padding {
        padding-bottom: 2rem!important;
    }
    .content-left {
        width: 100%;
        float: left!important;
    }
    .auth-box-w.wider,
    .auth-box-w.login-box {
        width: 352px !important;
        border-radius: 0px !important;
    }
    .kycIcon {
        width: 40px !important;
        height: 40px !important;
        margin-left: 6px;
    }
    .onboarding-title {
        font-size: 20px;
    }
    .auth-box-w form,
    .auth-box-w.login-box form {
        padding: 20px 20px 40px 20px !important;
        width: 351px !important;
    }
    .auth-box-w {
        background-color: transparent !important;
    }
    .auth-box-w.signupwidth {
        margin-top: 2em;
        min-width: 50% !important;
    }
    .auth-box-w .logo-w {
        padding: 20px 0 0 0 !important;
        width: 351px;
    }
    .auth-box-w {
        box-shadow: none !important;
    }
    .confirmPasswordContainer {
        margin-top: 1rem !important;
    }
    .terms-block,
    .info-bottom-link {
        font-size: 14px !important;
    }
    .copyright-text {
        font-size: 14px !important;
        padding-bottom: 10px !important;
    }
    .main-content-container {
        height: auto !important;
        margin-top: 0px !important;
    }
    .custom-form .input-group-text {
        top: 10% !important;
        padding: 10px !important;
    }
    .custom-form .form-control {
        padding: 12px !important;
    }
    .wrapper {
        margin-top: 10% !important;
    }
    .top-bar:not(.admin-top-bar) .top-menu-controls {
        padding: 10px 0 !important;
    }
    .split-radio-container label,
    .wealth-split-radio-container label,
    .understanding-split-radio-container label {
        min-width: 100% !important;
    }
    .main-content-container {
        padding-top: 0 !important;
    }
    .menu-container {
        background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 100%);
        background-repeat: repeat-x;
        color: rgba(255, 255, 255, 0.9);
    }
    .custom-top-bar {
        display: none !important;
    }
    .customLogo {
        margin-left: 0 !important;
    }
    .menu-mobile .mm-logo-buttons-w .mm-logo img {
        width: auto !important;
    }
    .dashboard-statistics-container:not(:last-child),
    .user-statistics-container {
        border-right: none !important;
    }
    .buy-xpr-header-block {
        font-size: 14px !important;
    }
    .dashboard-statistics-container {
        margin: 10px 0 !important;
    }
    .my-account-details:not(:last-child) {
        margin-bottom: 20px !important;
    }
    .onboarding-modal .onboarding-slide .onboarding-content {
        padding: 30px !important;
    }
    .my-account-container h5 span {
        font-weight: 0.8rem !important;
    }
    .my-account-container h5 span.pull-right {
        margin-top: 0.4rem !important;
    }
    .two-factor-block {
        text-align: center !important;
    }
    .two-factor-heading-text,
    .twofa-submit-button {
        margin-top: 15px !important;
    }
    .two-factor-heading-header {
        margin-top: 40px !important;
    }
    .buy-xpr-input,
    .tokens-xpr-input {
        width: 39% !important;
        padding-left: 1px !important;
        font-size: 14px !important;
        padding-right: 1px !important;
    }
    .buy-xpr-block {
        padding: 0.8rem !important;
    }
    .buy-xpr-container {
        font-size: 12px !important;
    }
    .converter-sign {
        padding-left: 0.5rem !important;
    }
    .xpr-tokens-label {
        padding-left: 0.5rem !important;
    }
    .buy-xpr-label {
        padding-right: 0.5rem !important;
        margin-top: 0.3rem !important;
    }
    .token-symbol-input {
        width: 20% !important;
        padding-top: 3px !important;
    }
    .eth-contribution-input {
        width: 100% !important;
        margin-top: 10px !important;
    }
    .buy-xpr-buttons {
        padding: 5px 15px !important;
        margin-top: 10px !important;
        margin-left: 0 !important;
    }
    .buy-online-payment-buttons {
        width: auto !important;
        margin-top: 10px !important;
    }
    .user-dashboard-content {
        min-height: 100vh !important;
    }
    .wallet-header {
        font-size: 14px !important;
    }
    .buy-xpr-container h6 {
        font-weight: 200 !important;
    }
    .identification-header {
        font-size: 18px !important;
    }
    .kyc-container.mt-4 {
        margin-top: 15px !important;
    }
    .identification-block .permian-button {
        padding: 15px 45px !important;
    }
    .country-top-margin {
        margin-top: 15px !important;
    }
    .our-service-container .iradio_square-blue {
        margin-left: 0px !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    .left-menu-sidebar {
        background: #1f377d;
        min-width: 7% !important;
    }
    .kycIcon {
        width: 40px !important;
        height: 40px !important;
        margin-left: 6px;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .kycIcon {
        width: 40px !important;
        height: 40px !important;
        margin-left: 6px;
    }
    /* .mm-logo-buttons-w {
    display: none !important;
  } */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 724px) and (orientation: landscape) {
    .exchange-content-container {
        padding: 0% !important;
    }
    .exchange-input2 {
        width: 100% !important;
    }
    .my-recent-orders-container {
        overflow-x: scroll !important;
    }
    .user-dashboard-content {
        margin-left: 0px !important;
    }
    .kycIcon {
        width: 40px !important;
        height: 40px !important;
        margin-left: 6px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .left-menu-sidebar {
        background-image: linear-gradient(to bottom, #1c2e7b 0%, #1c2e7b 100%);
        min-width: 0% !important;
    }
}

@media (max-width: 491px) {
    .hashrate-card-d-block {
        display: block !important;
    }
    .hashrate-card-button {
        margin-top: 20px;
        display: flex;
        text-align: center;
        width: 100%;
    }
    .hashrate-card-text {
        align-items: center;
        display: flex;
    }
}

@media screen and (max-device-width: 717px) {
    .manageuser-btn-group {
        margin-top: 10px !important;
        display: flex;
        justify-content: center;
        width: 97%;
    }
}

@media only screen and (min-device-width: 120px) and (max-device-width: 767px) {
    .mt-xs-3,
    .my-xs-3 {
        margin-top: 1rem !important;
    }
}

@media only screen and (max-device-width: 575px) {
    .login-container {
        margin-top: 0px;
    }
    .manage-progress-btn {
        display: flex;
        justify-content: center;
    }
    .content-end {
        display: unset!important;
    }
}

@media only screen and (max-device-width: 420px) {
    .react-confirm-alert-body {
        width: 90% !important;
        margin: auto !important;
    }
}

@media only screen and (max-device-width: 600px) {
    .onboarding-content {
        min-width: 85vw!important;
    }
}

@media only screen and (max-device-width: 620px) {
    .content-end {
        justify-content: flex-start!important;
    }
    .user-detail-heading {
        display: unset!important;
    }
}

@media screen and (max-width: 460px) {
    .dashboard_text_space {
        margin-left: 20px !important;
    }
}

@-moz-document url-prefix() {
    .select-icon select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../img/down-arrow.svg) right / 20px no-repeat #fff;
        padding-right: 20px;
    }
}

@supports (-webkit-touch-callout: none) {
    .select-icon SELECT {
        -moz-appearance: none !important;
        background: transparent url(../img/down-arrow.svg?v3) right center no-repeat !important;
    }
}